import React, { useEffect, useState } from "react";
import "./Homep.css";
// import "./FAQLanding.style.css";
import { useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";
import Homeimg from "../images/Homep1.svg";
import Mobile from "../images/mobile.svg";
import Sisters from "../images/Sisters.svg";
import Section3 from "../images/section3.png";
import FAQ from "../images/faqe.svg";
import driveimf from "../images/drivebuga.svg";
import mobileImage from "../images/Trustedbymobile.svg";
import desktopImage from "../images/TrustedbyDesktop.svg";
import MobileappImage from "../images/Mobliaapp.svg";
import Mobilemap from "../images/mobilemap.svg";
import wheel from "../images/wheels.svg";
import FAQLanding from "../../../Components/FAQLanding";
import Guymobile from "../images/guyman.svg";
import Guydesktop from "../images/guydesktop.svg";

import Carousel from "../../../Components/Carousel/Carousel";
import AutoPlaySlider from "../../../Components/AutoPlaySlider/AutoPlaySlider";

function Homep() {
    const [on1, setON1] = useState(true);
  const [on2, setON2] = useState(false);
  const [on3, setON3] = useState(false);
  const [on4, setON4] = useState(false);
  const [on5, setON5] = useState(false);
  const [on6, setON6] = useState(false);
  const [on7, setON7] = useState(false);
  const [on8, setON8] = useState(false);
  const [on9, setON9] = useState(false);
  const [on10, setON10] = useState(false);
  var [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const breakpoint = 890;

      if (width >= breakpoint) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handlabs = () => {
    navigate("/ambasador");
  };
  // const slides = [
  //   { text: "Slide 1" },
  //   { text: "Slide 2" },
  //   { text: "Slide 3" },
  //   { text: "Slide 4" },
  //   { text: "Slide 5" },
  // ];

  return (
    <>
    <Navbar />
    <main>
      <div className="body-homep">
        <div className="head-homep">
          <div style={{ fontFamily: "Satoshi",}} className="Homp-center-text">
            <h2>
              Transforming{" "}
              <span style={{ color: "#FFCC2A" }}> Mobility </span>in Africa.
            </h2>
            <p>
              Helping You Travel To, From and Around Safely and
              Comfortably.
            </p>
            <button className="btn-Homep-waitlist">Join Our Waitlist</button>
          </div>
          <br />
          <br />
          <div>
          <center>  <img src={Homeimg} width='100%' alt="Student-Image" /></center>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <section className="Homep-middle">
          <div className="need-a-ride">
            <div className="school-ride-homep">
              <h2>
                Need a ride?
                <br />
                <br /> We’ve got you!
              </h2>
            </div>
            <div className="students-homep">
              <img className="sty" src={Sisters} alt="student-Image" />
            </div>
          </div>
          <div>
            <button className="btn-Homep-waitlist-2">
              Join Our Waitlist
            </button>
          </div>
          <center>
              <img
                src={mobileImage}
                alt="Mobile Image"
              /></center>
            
          <div className="communte">
            <h2>
              Commute with ease and{" "}
              <span
                style={{
                  color: "#FFCC2A",
                }}
              >
                {" "}
                peace of mind
              </span>
            </h2>
            <p>
              Buga Travels is more than just a ride-hailing app - it's a
              trusted companion for all your campus transportation needs, with
              vetted drivers, real-time tracking, and emergency features at
              your fingertips.
            </p>
          </div>
          <div>
            <Carousel />
          </div>
          <br />
          <br />
          <br />
          <br />
          <center>
            <section className="Cruise-homep">
              <h2>Travelling solo or splitting the bill?</h2>
              <p>
                {" "}
                We’ve <span style={{ color: "#FFCC2A" }}>got you</span>
              </p>
              <img src={MobileappImage} alt="mobileapp" />
            </section>
          </center>
          <br />
          <b />
          <div className="background-img-cut">
            <div className="Homep-map-img">
              <img
                className="mobile-buga"
                src={Mobilemap}
                alt="mobile map image"
              />
            </div>
            
            <section className="text-background">
              <h2 id="head-text-order">Ordering a ride is easy!</h2>
              <div>
                <h2>Create an Account</h2>
                <p>
                  Sign up for an account to access personalized features,
                  manage your bookings, and enjoy a seamless experience.
                </p>
              </div>
              <div>
                <h2>Enter Your Destination</h2>
                <p>
                  Input your destination details and let our advanced system
                  guide you to the best routes and options for a smooth travel
                  experience.
                </p>
              </div>
              <div>
                <h2>Negotiate with your driver and get moving!</h2>
                <p>
                  Name your price using our price range figures as a guide,
                  and select a preferred driver from the numerous offers
                  rolling in.
                </p>
              </div>
              <button className="btn-join-black">Join Our Waitlist</button>
            </section>
          </div>

          <div className="Homep-community-join">
            <h2>Join Our Exclusive Community of Ambassadors</h2>
          </div>
          <div className="guysmile">
            <div className="guytext">
              <div className="mbassdor-text">
                <h2>
                  "Buga Travels has helped me develop social and digital
                  skills through their ambassador program. I’ve also come in
                  contact with valuable networks that’ll aid my growth. They
                  are so intentional about the growth of their ambassadors"
                </h2>
              </div>
              <div className="btn-amassdor">
                <button className="guy-btn">Join 200+ ambassadors</button>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="image-container">
        {isMobile ? (
          <img
            className="guy-render-mobile"
            src={Guymobile}
            alt="Mobile Image"
          />
        ) : (
          <img src={Guydesktop} alt="Desktop Image" />
        )}
      </div> */}
        <div className="Homep-black-bg">
            <img src={driveimf} />

            <img src={wheel} alt="driving image" />
        </div>
        <center>
        <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <br />
            <img src={FAQ} style={{ paddingLeft: "20px" }} />
          </div>
          <div className="col-lg-8 col-sm-12 d-none d-lg-block">
            <div
              style={{
                height: "100%",
                marginLeft: "10px",
                boxShadow:
                  "0px 0.2701253294944763px 13.506266593933105px rgba(0, 0, 0, 0.10)",
                borderRadius: 5.4,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    height: on1 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on1 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on1 ? (
                      <div
                        style={{
                          
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14.25,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels h a dedicated emergency support system in
                        place to sist drivers in ce of emergencies. If you
                        encounter an emergency situation while on a ride, you
                        can use the emergency button within the app to
                        immediately notify our support team. They will provide
                        guidance and necessary sistance to ensure your safety
                        and the safety of your psengers.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        tFamily: "General Sans",
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      01
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        What type of support does Buga Travels provide to
                        drivers in ce of emergencies?
                      </div>
                      <div
                        onClick={() => {
                          setON1(!on1);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on1 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on2 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on2 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on2 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Currently, Buga Travels does not have a psenger rating
                        system. However, we encourage drivers to provide
                        feedback and report any issues they may encounter
                        during a ride. This feedback helps us monitor and
                        address any concerns related to psenger behaviour
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      02
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Is there a rating system for psengers well?
                      </div>
                      <div
                        onClick={() => {
                          setON2(!on2);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on2 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on3 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on3 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on3 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, Buga Travels offers a rental program in
                        partnership with certain vehicle rental companies. If
                        you meet our driver requirements and don't own a
                        vehicle, you may have the option to rent a vehicle
                        through our approved partners to fulfill ride
                        requests.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        
                        wordWrap: "break-word",
                      }}
                    >
                      03
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        {" "}
                        Can I drive for Buga Travels if I don't own a vehicle?
                      </div>
                      <div
                        onClick={() => {
                          setON3(!on3);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on3 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on4 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on4 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on4 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          fontWeight: "500",
                          
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, a Buga Travels driver, you are expected to adhere
                        to our driver guidelines and local transportation
                        regulations. These guidelines ensure a safe and
                        reliable experience for both drivers and psengers.
                        It's important to review and comply with these rules
                        to maintain your partnership with Buga Travels
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        
                        wordWrap: "break-word",
                      }}
                    >
                      04
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Are there any specific rules or regulations I should
                        be aware of a Buga Travels driver?
                      </div>
                      <div
                        onClick={() => {
                          setON4(!on4);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on4 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on5 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on5 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on5 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, Buga Travels provides flexibility for drivers to
                        set their availability bed on their schedule. You can
                        customize your availability within the driver app,
                        indicating the days and hours you are willing to
                        accept ride requests.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      06
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          
                          fontSize: 16.87,
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Can I customize my availability a driver?
                      </div>
                      <div
                        onClick={() => {
                          setON5(!on5);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on5 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on6 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on6 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on6 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        If a psenger cancels a ride, you will be notified
                        through the app, and the ride will be marked canceled.
                        In such ces, you may receive a cancellation fee
                        depending on the specific circumstances and our
                        cancellation policy.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      06
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        What happens if a psenger cancels a ride?
                      </div>
                      <div
                        onClick={() => {
                          setON6(!on6);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on6 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on7 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on7 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on7 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels takes disputes between drivers and
                        psengers seriously. If a dispute arises, we encourage
                        both parties to provide detailed information through
                        the app or by contacting customer support. Our team
                        will thoroughly investigate the matter and work
                        towards a fair resolution.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      07
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        How does Buga Travels handle disputes between drivers
                        and psengers?
                      </div>
                      <div
                        onClick={() => {
                          setON7(!on7);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on7 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on8 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on8 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on8 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Currently, Buga Travels primarily focuses on providing
                        transportation services for university students and
                        shuttle services to and from the airport. Additional
                        services beyond the scope of psenger transportation
                        may not be supported at this time
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      08
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Can I use Buga Travels to offer additional services,
                        such courier deliveries?
                      </div>
                      <div
                        onClick={() => {
                          setON8(!on8);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on8 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on9 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on9 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on9 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          fontWeight: "500",
                          
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels employs a dynamic pricing model that
                        takes into account factors such distance, time, and
                        demand. This helps ensure fair compensation for
                        drivers and reonable pricing for psengers, considering
                        market conditions and operational costs.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      09
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        How does Buga Travels ensure fair pricing for both
                        drivers and psengers?
                      </div>
                      <div
                        onClick={() => {
                          setON9(!on9);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on9 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              tFamily: "General Sans",
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on10 ? 200 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on10 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 600.99, position: "relative" }}>
                    {on10 ? (
                      <div
                        style={{
                          
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels operates in multiple cities across
                        Nigeria and Africa. If you are approved a driver, you
                        may have the opportunity to drive in different cities
                        where Buga Travels operates, expanding your potential
                        to serve a wider range of riders.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      10
                    </div>
                    <div
                      style={{
                        
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        {" "}
                        Can I drive for Buga Travels in multiple cities?
                      </div>
                      <div
                        onClick={() => {
                          setON10(!on10);
                        }}
                        style={{
                          left: 555.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on10 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" pad-top-sm col-lg-8 col-sm-12 d-lg-none">
            <div
              style={{
                width: 'auto',
                height: "100%",
                boxShadow:
                  "0px 0.2701253294944763px 13.506266593933105px rgba(0, 0, 0, 0.10)",
                borderRadius: 5.4,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    height: on1 ? 300 : 110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on1 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on1 ? (
                      <div
                        style={{
                          
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14.25,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels h a dedicated emergency support system in
                        place to sist drivers in ce of emergencies. If you
                        encounter an emergency situation while on a ride, you
                        can use the emergency button within the app to
                        immediately notify our support team. They will provide
                        guidance and necessary sistance to ensure your safety
                        and the safety of your psengers.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        tFamily: "General Sans",
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      01
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        What type of support does Buga Travels provide to
                        drivers in ce of emergencies?
                      </div>
                      <div
                        onClick={() => {
                          setON1(!on1);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on1 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on2 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on2 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on2 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Currently, Buga Travels does not have a psenger rating
                        system. However, we encourage drivers to provide
                        feedback and report any issues they may encounter
                        during a ride. This feedback helps us monitor and
                        address any concerns related to psenger behaviour
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      02
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Is there a rating system for psengers well?
                      </div>
                      <div
                        onClick={() => {
                          setON2(!on2);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on2 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on3 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on3 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on3 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, Buga Travels offers a rental program in
                        partnership with certain vehicle rental companies. If
                        you meet our driver requirements and don't own a
                        vehicle, you may have the option to rent a vehicle
                        through our approved partners to fulfill ride
                        requests.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        
                        wordWrap: "break-word",
                      }}
                    >
                      03
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        {" "}
                        Can I drive for Buga Travels if I don't own a vehicle?
                      </div>
                      <div
                        onClick={() => {
                          setON3(!on3);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on3 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on4 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on4 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on4 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          fontWeight: "500",
                          
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, a Buga Travels driver, you are expected to adhere
                        to our driver guidelines and local transportation
                        regulations. These guidelines ensure a safe and
                        reliable experience for both drivers and psengers.
                        It's important to review and comply with these rules
                        to maintain your partnership with Buga Travels
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        
                        wordWrap: "break-word",
                      }}
                    >
                      04
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Are there any specific rules or regulations I should
                        be aware of a Buga Travels driver?
                      </div>
                      <div
                        onClick={() => {
                          setON4(!on4);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on4 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on5 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on5 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on5 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Yes, Buga Travels provides flexibility for drivers to
                        set their availability bed on their schedule. You can
                        customize your availability within the driver app,
                        indicating the days and hours you are willing to
                        accept ride requests.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      06
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          
                          fontSize: 16.87,
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Can I customize my availability a driver?
                      </div>
                      <div
                        onClick={() => {
                          setON5(!on5);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on5 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on6 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on6 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on6 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        If a psenger cancels a ride, you will be notified
                        through the app, and the ride will be marked canceled.
                        In such ces, you may receive a cancellation fee
                        depending on the specific circumstances and our
                        cancellation policy.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      06
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        What happens if a psenger cancels a ride?
                      </div>
                      <div
                        onClick={() => {
                          setON6(!on6);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on6 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on7 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on7 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on7 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels takes disputes between drivers and
                        psengers seriously. If a dispute arises, we encourage
                        both parties to provide detailed information through
                        the app or by contacting customer support. Our team
                        will thoroughly investigate the matter and work
                        towards a fair resolution.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      07
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        How does Buga Travels handle disputes between drivers
                        and psengers?
                      </div>
                      <div
                        onClick={() => {
                          setON7(!on7);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on7 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              
                              fontWeight: 500,
                              fontSize: 20,
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on8 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on8 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on8 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Currently, Buga Travels primarily focuses on providing
                        transportation services for university students and
                        shuttle services to and from the airport. Additional
                        services beyond the scope of psenger transportation
                        may not be supported at this time
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      08
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        Can I use Buga Travels to offer additional services,
                        such courier deliveries?
                      </div>
                      <div
                        onClick={() => {
                          setON8(!on8);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on8 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on9 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on9 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on9 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          fontSize: 14,
                          fontWeight: "500",
                          
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels employs a dynamic pricing model that
                        takes into account factors such distance, time, and
                        demand. This helps ensure fair compensation for
                        drivers and reonable pricing for psengers, considering
                        market conditions and operational costs.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      09
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        How does Buga Travels ensure fair pricing for both
                        drivers and psengers?
                      </div>
                      <div
                        onClick={() => {
                          setON9(!on9);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on9 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              tFamily: "General Sans",
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: on10 ? 300 :110,
                    paddingTop: 18.74,
                    paddingBottom: 18.74,
                    paddingLeft: 18.74,
                    paddingRight: 37.49,
                    background: on10 ? "#FFCC2A" : "white",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 7.5,
                    display: "flex",
                  }}
                >
                  <div style={{ width: 300.99, position: "relative" }}>
                    {on10 ? (
                      <div
                        style={{
                          width: 300,
                          height: 89.03,
                          left: 32.8,
                          top: 78.72,
                          position: "absolute",
                          textAlign: "justify",
                          color: "black",
                          
                          fontSize: 14,
                          fontWeight: "500",
                          wordWrap: "break-word",
                        }}
                      >
                        Buga Travels operates in multiple cities across
                        Nigeria and Africa. If you are approved a driver, you
                        may have the opportunity to drive in different cities
                        where Buga Travels operates, expanding your potential
                        to serve a wider range of riders.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        position: "absolute",
                        color: "#121212",
                        fontSize: 18.74,
                        
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      10
                    </div>
                    <div
                      style={{
                        width: 300,
                        height: 45,
                        left: 32.8,
                        top: 0,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          left: 0,
                          top: 0,
                          position: "absolute",
                          color: "#151515",
                          fontSize: 16.87,
                          
                          fontWeight: "600",
                          wordWrap: "break-word",
                          paddingRight: 40,
                        }}
                      >
                        {" "}
                        Can I drive for Buga Travels in multiple cities?
                      </div>
                      <div
                        onClick={() => {
                          setON10(!on10);
                        }}
                        style={{
                          left: 255.85,
                          top: 7.5,
                          position: "absolute",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            width: 28.34,
                            height: 28.34,
                            borderRadius: 9999,
                            border: "0.14px black solid",
                          }}
                        />
                        {on10 ? (
                          <div
                            style={{
                              width: 9.63,
                              height: 9.63,
                              left: 9.48,
                              top: -3,
                              fontWeight: 500,
                              fontSize: 20,
                              
                              position: "absolute",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 22.23,
                              height: 22.23,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 9.26,
                                height: 4.63,
                                left: 9.48,
                                top: -16.26,
                                
                                position: "absolute",
                                background: "black",
                                border: "0.14px black solid",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </center>

        <div className="reviews">
          <AutoPlaySlider />
        </div>
        <div className="Form">
          {" "}
          <center>
            <div className="Form-text">
              <h2>Stay in the loop!</h2>
              <p>
                Receive the latest updates, special promotions, and exclusive
                offers just for you
              </p>
              <div className="sp-form">
                <MdEmail className="md-sp " />
                <input
                  className="in-sp"
                  type="text"
                  placeholder="Email Address"
                />
                <button className="btn-sub">Subscribe</button>
              </div>
            </div>
          </center>
        </div>
      </div>
    </main>
    <Footer />
  </>
  );
}

export default Homep;
