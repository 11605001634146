import React from "react";
import "./Home.css";

function Home() {
  return (
    <>
      <center>
        <div style={{ color: "#FFCC2A" }} className="comeon">
          <h2>We are working on our site </h2>
          <br />

          {/* <h2>check back soon.</h2> */}
        </div>
      </center>
    </>
  );
}

export default Home;
